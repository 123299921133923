/* eslint-env jquery */

"use strict";

const gtm = require("../../gtm");

window.addEventListener("load", function () {
    const Swiper = require("swiper").default;
    const Navigation = require("swiper").Navigation;
    const Pagination = require("swiper").Pagination;
    const Scrollbar = require("swiper").Scrollbar;

    if (!window.ahumadaBannerCountDownInitialized) {

        const carousels = $(".js-swiper-products-carousel-countdown");

        carousels.each(function () {

            var eventWasTriggered = false;
            const $swiperWrapper = $(this);
            const swipperWrapperClass = `.swipper-${$swiperWrapper.data("id")}`;

            let slidesToShow, slidesToShowMobile;

            try {
                slidesToShow = parseInt($swiperWrapper.data("slides-to-show"), 10) || 5;
                slidesToShowMobile = parseInt($swiperWrapper.data("slides-to-show-mobile"), 10) || 2;
            } catch (e) {
                slidesToShow = 5;
                slidesToShowMobile = 2;
            }

            const loopEnabled = $swiperWrapper.data("loop-enabled");
            const navEnabled = $swiperWrapper.data("navigation-enabled");
            const paginationEnabled = $swiperWrapper.data("pagination-enabled");
            const scrollbarEnabled = $swiperWrapper.data("scroll-enabled");
            const carouselModules = [];

            if (navEnabled) {
                carouselModules.push(Navigation);
            }

            if (paginationEnabled) {
                carouselModules.push(Pagination);
            }

            if (scrollbarEnabled) {
                carouselModules.push(Scrollbar);
            }

            new Swiper(`${swipperWrapperClass} .js-swiper-container`, {
                loop: Boolean(loopEnabled),
                modules: carouselModules,
                pagination: {
                    el: `${swipperWrapperClass} .js-swiper-pagination`,
                    type: "bullets",
                    clickable: true,
                },
                navigation: {
                    nextEl: `${swipperWrapperClass} .js-swiper-button-next`,
                    prevEl: `${swipperWrapperClass} .js-swiper-button-prev`,
                },
                scrollbar: {
                    el: `${swipperWrapperClass} .js-swiper-scrollbar`,
                },
                slidesPerView: slidesToShowMobile,
                spaceBetween: 16,
                breakpoints: {
                    1024: {
                        slidesPerView: slidesToShow,
                        spaceBetween: 16,
                    },
                },
            });

            $(window).on("scroll", function () {
                if (!eventWasTriggered && $swiperWrapper.is(":visible") && $swiperWrapper.find(".product-tile").length) {
                    var windowHeight  = $(window).height();
                    var scrollTop     = $(window).scrollTop();
                    var elementOffset = $swiperWrapper.offset().top;

                    if (scrollTop + windowHeight > elementOffset) {
                        // Run tracking function when the element is on the screen
                        gtm.viewItemList($swiperWrapper);
                        eventWasTriggered = true;
                    }
                }
            });
        });

        $(".expirydiv").each(function () {
            // padrao esperado yyyy/mm/dd hh:mm:ss 2024/09/27 10:59:00
            var timer = $(this).attr("data-promo");
            const banner = $(this);
            const bannerId = banner.data("id");
            const countDown = $(".carousel-countdown-"+bannerId);

            var countDownDate = new Date(timer).getTime();
            var $this = $(this);
            var x = setInterval(function () {
            
                var nowFuso = new Date().toISOString();
                var now = new Date(nowFuso).getTime();
                var distance = countDownDate - now;
                var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            
                var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                var seconds = Math.floor((distance % (1000 * 60)) / 1000);
            
                hours = days > 0 ? (days*24)+hours: hours;
                var hoursText = hours > 99 ? ("000" + hours).slice(-3) : ("00" + hours).slice(-2);
                // $this.text(hoursText + ":" +
                // ("00" + minutes).slice(-2) + ":" + ("00" + seconds).slice(-2));
                const countDownMinutos = $(".expirydivMinutos"+bannerId);
                const countDownSegundos = $(".expirydivSegundos"+bannerId);                
                $this.text(hoursText);
                countDownMinutos.text(("00" + minutes).slice(-2));
                countDownSegundos.text(("00" + seconds).slice(-2));
        
                if (distance < 0) {                    
                    clearInterval(x);
                    countDown.addClass("d-none");
                } else {
                    if (countDown.hasClass("d-none")) {
                        countDown.removeClass("d-none");
                    }
                }            
            }, 1000);
        });
    
        window.ahumadaBannerCountDownInitialized = true;
    }
});
