"use strict";

var base = require("../product/base");
var focusHelper = require("base/components/focus");
const { initPopover } = require("../../js/utils/popoverUtils");
var shippingHelpers = require("../checkout/shipping");
const { updateCountryInputValue, methods } = require("../addressBook/addressBook");
const { initCarousel } = require("ahumada/components/carousels");
var utilMask = require("ahumada/utils/masks");
var formValidation = require("../components/formValidation");
var utilsServices = require ("../utils/utilsServices");
var gtm = require("../gtm");
const { functions: { clearForm } } = require("../components/clientSideValidation");

// CONSTANTS
const $notificationAlert = $(".notification-alert");

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    var newUrl = url;
    newUrl += (newUrl.indexOf("?") !== -1 ? "&" : "?") + Object.keys(params).map(function (key) {
        return key + "=" + encodeURIComponent(params[key]);
    }).join("&");

    return newUrl;
}

/**
* Serializes the address modal data from a form
* @param {Object} form - the form to serialize
* @returns {string} - the serialized form data
*/
function serializeAddressModalData(form) {
    var filteredArray = form.serializeArray()
        .filter(field => !(field.name.includes("_country") && field.value.length > 2));

    return $.param(filteredArray);
}

/**
 * Checks whether the basket is valid. if invalid displays error message and disables
 * checkout button
 * @param {Object} data - AJAX response from the server
 */
function validateBasket(data) {
    if (data.valid.error) {
        if (data.valid.message) {
            var errorHtml = "<div class=\"alert alert-danger alert-dismissible valid-cart-error " +
                "fade show\" role=\"alert\">" +
                "<button type=\"button\" class=\"close\" data-dismiss=\"alert\" aria-label=\"Close\">" +
                "<span aria-hidden=\"true\">&times;</span>" +
                "</button>" + data.valid.message + "</div>";

            $(".cart-error").append(errorHtml);
        } else if (!("isFulfilledByAhumada" in data.valid)) {
            $(".cart").empty().append("<div class=\"row\"> " +
                "<div class=\"col-12\"> " +
                data.resources.emptyCartMsg +
                "</div> " +
                "</div>"
            );
            $(".number-of-items").empty().append(data.resources.numberOfItems);
            $(".minicart-quantity").empty().append(data.numItems);
            $(".minicart-link").attr({
                "aria-label": data.resources.minicartCountOfItems,
                title: data.resources.minicartCountOfItems
            });
            $(".minicart .popover").empty();
            $(".minicart .popover").removeClass("show");
        }
    }

    $(".checkout-btn", ".cart-page").toggleClass("disabled", !data.shippable);
    $(".error-no-address").toggleClass("d-none", data.shippable);
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} data - AJAX response from the server
 */
function updateCartTotals(data) {
    $(".number-of-items").empty().append(data.resources.numberOfItems);
    $(".shipping-cost").empty().append(data.totals.totalShippingCost);
    $(".tax-total").empty().append(data.totals.totalTax);
    $(".grand-total").empty().append(data.totals.grandTotal);
    $(".sub-total").empty().append(data.totals.subTotal);
    $(".minicart-quantity").empty().append(data.numItems);
    $(".minicart-link").attr({
        "aria-label": data.resources.minicartCountOfItems,
        title: data.resources.minicartCountOfItems
    });
    if (data.totals.orderLevelDiscountTotal.value > 0) {
        $(".order-discount").removeClass("hide-order-discount");
        $(".order-discount-total").empty()
            .append("- " + data.totals.orderLevelDiscountTotal.formatted);
    } else {
        $(".order-discount").addClass("hide-order-discount");
    }

    if (data.totals.shippingLevelDiscountTotal.value > 0) {
        $(".shipping-discount").removeClass("hide-shipping-discount");
        $(".shipping-discount-total").empty().append("- " +
            data.totals.shippingLevelDiscountTotal.formatted);
    } else {
        $(".shipping-discount").addClass("hide-shipping-discount");
    }

    let allLineItems = {};
    let quantitySelector;

    data.items.forEach(function (item) {
        $(".item-" + item.UUID).empty().append(item.renderedPromotions);
        $(".uuid-" + item.UUID + " .unit-price").empty().append(item.renderedPrice);
        $(".item-total-" + item.UUID).empty().append(item.priceTotal.renderedPrice);

        quantitySelector = $("[name=quantity-" + item.UUID + "]");
        if (quantitySelector.length && quantitySelector.data("max")) {
            var maxQuantity          = parseInt(quantitySelector.data("max"), 10);
            var isFulfilledByAhumada = parseInt($("option:selected", quantitySelector).val(), 10) <= maxQuantity;
            allLineItems[item.UUID] = isFulfilledByAhumada;
            if (isFulfilledByAhumada) {
                quantitySelector.removeClass("is-invalid");
                quantitySelector.next(".invalid-feedback").remove();
            }
        }
    });

    if (!Object.values(allLineItems).includes(false)) {
        $(".cart-out-of-stock-card").remove();
    }

    $(".coupons-and-promos").empty().append(data.totals.discountsHtml);
    $(".js-applied-promotions").empty().append(data.totals.appliedPromotionsHtml);
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} message - Error message to display
 */
function createErrorNotification(message) {
    var errorHtml = "<div class=\"alert alert-danger alert-dismissible valid-cart-error " +
        "fade show\" role=\"alert\">" +
        "<button type=\"button\" class=\"close\" data-dismiss=\"alert\" aria-label=\"Close\">" +
        "<span aria-hidden=\"true\">&times;</span>" +
        "</button>" + message + "</div>";

    $(".cart-error").append(errorHtml);
}

/**
 * re-renders the approaching discount messages
 * @param {Object} approachingDiscounts - updated approaching discounts for the cart
 */
function updateApproachingDiscounts(approachingDiscounts) {
    var html = "";
    $(".approaching-discounts").empty();
    if (approachingDiscounts.length > 0) {
        approachingDiscounts.forEach(function (item) {
            html += "<div class=\"single-approaching-discount text-center\">"
                + item.discountMsg + "</div>";
        });
    }
    $(".approaching-discounts").append(html);
}

/**
 * Updates the availability of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateAvailability(data, uuid) {
    var lineItem;
    var messages = "";

    for (var i = 0; i < data.items.length; i++) {
        if (data.items[i].UUID === uuid) {
            lineItem = data.items[i];
            break;
        }
    }

    if (lineItem != null) {
        $(".availability-" + lineItem.UUID).empty();

        if (lineItem.availability) {
            if (lineItem.availability.messages) {
                lineItem.availability.messages.forEach(function (message) {
                    messages += "<p class=\"line-item-attributes\">" + message + "</p>";
                });
            }

            if (lineItem.availability.inStockDate) {
                messages += "<p class=\"line-item-attributes line-item-instock-date\">"
                    + lineItem.availability.inStockDate
                    + "</p>";
            }
        }

        $(".availability-" + lineItem.UUID).html(messages);
    }
}

/**
 * Finds an element in the array that matches search parameter
 * @param {array} array - array of items to search
 * @param {function} match - function that takes an element and returns a boolean indicating if the match is made
 * @returns {Object|null} - returns an element of the array that matched the query.
 */
function findItem(array, match) { // eslint-disable-line no-unused-vars
    for (var i = 0, l = array.length; i < l; i++) {
        if (match.call(this, array[i])) {
            return array[i];
        }
    }
    return null;
}

/**
 * Updates details of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateProductDetails(data, uuid) {
    $(".card.product-info.uuid-" + uuid).replaceWith(data.renderedTemplate);
}

/**
 * Generates the modal window on the first call.
 *
 */
function getModalHtmlElement() {
    if ($("#editProductModal").length !== 0) {
        $("#editProductModal").remove();
    }
    var htmlString = "<!-- Modal -->"
        + "<div class=\"modal fade\" id=\"editProductModal\" tabindex=\"-1\" role=\"dialog\">"
        + "<span class=\"enter-message sr-only\" ></span>"
        + "<div class=\"modal-dialog quick-view-dialog\">"
        + "<!-- Modal content-->"
        + "<div class=\"modal-content\">"
        + "<div class=\"modal-header\">"
        + "    <button type=\"button\" class=\"close pull-right\" data-dismiss=\"modal\">"
        + "        <span aria-hidden=\"true\">&times;</span>"
        + "        <span class=\"sr-only\"> </span>"
        + "    </button>"
        + "</div>"
        + "<div class=\"modal-body\"></div>"
        + "<div class=\"modal-footer\"></div>"
        + "</div>"
        + "</div>"
        + "</div>";
    $("body").append(htmlString);
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    var $html = $("<div>").append($.parseHTML(html));

    var body = $html.find(".product-quickview");
    var footer = $html.find(".modal-footer").children();

    return { body: body, footer: footer };
}

/**
 * replaces the content in the modal window for product variation to be edited.
 * @param {string} editProductUrl - url to be used to retrieve a new product model
 */
function fillModalElement(editProductUrl) {
    $(".modal-body").spinner().start();
    $.ajax({
        url: editProductUrl,
        method: "GET",
        dataType: "json",
        success: function (data) {
            var parsedHtml = parseHtml(data.renderedTemplate);

            $("#editProductModal .modal-body").empty();
            $("#editProductModal .modal-body").html(parsedHtml.body);
            $("#editProductModal .modal-footer").html(parsedHtml.footer);
            $("#editProductModal .modal-header .close .sr-only").text(data.closeButtonText);
            $("#editProductModal .enter-message").text(data.enterDialogMessage);
            $("#editProductModal").modal("show");
            $("body").trigger("editproductmodal:ready");
            window.location.reload();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * replace content of modal
 * @param {string} actionUrl - url to be used to remove product
 * @param {string} productID - pid
 * @param {string} productName - product name
 * @param {string} uuid - uuid
 */
function confirmDelete(actionUrl, productID, productName, uuid) {
    var $deleteConfirmBtn = $(".cart-delete-confirmation-btn");
    var $productToRemoveSpan = $(".product-to-remove");

    $deleteConfirmBtn.data("pid", productID);
    $deleteConfirmBtn.data("action", actionUrl);
    $deleteConfirmBtn.data("uuid", uuid);

    $productToRemoveSpan.empty().append(productName);
}

/**
 * Deletes items from the shopping cart and updates the cart UI accordingly
 * @param {string} url - the URL to send the delete request to
 * @param {string} uuid - the UUID of the item to be deleted (optional)
 * @returns {void}
 */
function deleteItemsFromBasket(url, uuid) {
    $.ajax({
        url: url,
        type: "get",
        dataType: "json",
        /**
         * Success callback function for an AJAX request to update the cart
         * @param {Object} data - The response data from the AJAX request
         */
        success: function (data) {
            var uuidsToBeDeleted = data.toBeDeletedUUIDs;

            if (uuidsToBeDeleted && uuidsToBeDeleted.length > 0) {
                for (var i = 0; i < uuidsToBeDeleted.length; i++) {
                    uuid = uuidsToBeDeleted[i];

                    var isMinicart = $(".uuid-" + uuid).parents(".minicart").length;
                    var gtmProductWrapper = isMinicart ? $(".uuid-" + uuid).find(".product-line-item") : $(".uuid-" + uuid);
                    var quantitySelect = $(".uuid-" + uuid).find("select.quantity");
                    var quantity = quantitySelect.val();
                    gtm.removeFromCart(gtmProductWrapper, quantity, isMinicart);
                }
            }

            if (data.basket.items.length === 0) {
                $(".cart").empty().append("<div class=\"row\"> " +
                    "<div class=\"col-12\"> " +
                    data.basket.resources.emptyCartMsg +
                    "</div> " +
                    "</div>"
                );
                $(".number-of-items").empty().append(data.basket.resources.numberOfItems);
                $(".minicart-quantity").empty().append(data.basket.numItems);
                $(".minicart-link").attr({
                    "aria-label": data.basket.resources.minicartCountOfItems,
                    title: data.basket.resources.minicartCountOfItems
                });
                $(".minicart .popover").empty();
                $(".minicart .popover").removeClass("show");
                $("body").removeClass("modal-open");
                $("html").removeClass("veiled");
            } else {
                if (uuidsToBeDeleted && uuidsToBeDeleted.length > 0) {
                    for (var j = 0; j < uuidsToBeDeleted.length; j++) {
                        $(".uuid-" + uuidsToBeDeleted[j]).remove();
                    }
                } else if (uuid) {
                    $(".uuid-" + uuid).remove();
                }

                if (!data.basket.hasBonusProduct) {
                    $(".bonus-product").remove();
                }
                $(".coupons-and-promos").empty().append(data.basket.totals.discountsHtml);
                updateCartTotals(data.basket);
                updateApproachingDiscounts(data.basket.approachingDiscounts);
                $("body").trigger("setShippingMethodSelection", data.basket);
                validateBasket(data.basket);
            }

            $("body").trigger("cart:update", data);

            if (!uuid) {
                $(".cart-out-of-stock-card").remove();
            }
            window.location.reload();
        },
        error: function (err) {
            if (err.responseJSON.redirectUrl) {
                window.location.href = err.responseJSON.redirectUrl;
            } else {
                createErrorNotification(err.responseJSON.errorMessage);
                $.spinner().stop();
            }
        }
    });
}

module.exports = function () {
    $("body").on("click", ".remove-product", function (e) {
        e.preventDefault();

        var actionUrl = $(this).data("action");
        var productID = $(this).data("pid");
        var productName = $(this).data("name");
        var uuid = $(this).data("uuid");
        confirmDelete(actionUrl, productID, productName, uuid);
    });

    $("body").on("afterRemoveFromCart", function (e, data) {
        e.preventDefault();
        confirmDelete(data.actionUrl, data.productID, data.productName, data.uuid);
    });

    $(".optional-promo").on("click", function (e) {
        e.preventDefault();
        $(".promo-code-form").toggle();
    });

    $("body").on("click", ".cart-delete-confirmation-btn", function (e) {
        e.preventDefault();

        var productID = $(this).data("pid");
        var url = $(this).data("action");
        var uuid = $(this).data("uuid");
        var urlParams = {
            pid: productID,
            uuid: uuid
        };

        url = appendToUrl(url, urlParams);

        $("body > .modal-backdrop").remove();

        $.spinner().start();

        $("body").trigger("cart:beforeUpdate");

        deleteItemsFromBasket(url, uuid);
    });

    $("body").on("change", ".quantity-form > .quantity", function () {
        var preSelectQty = $(this).attr("data-pre-select-qty");
        var quantity = $(this).val();
        var productID = $(this).data("pid");
        var url = $(this).data("action");
        var uuid = $(this).data("uuid");
        const isMinicart = $(this).parents(".minicart").length;
        const $productContainer = $(this).parents(".minicart").length ? $(this).closest(".product-line-item") : $(this).closest(".product-info");

        var urlParams = {
            pid: productID,
            quantity: quantity,
            uuid: uuid
        };
        url = appendToUrl(url, urlParams);

        $.spinner().start();
        $("body").trigger("cart:beforeUpdate");

        $.ajax({
            url: url,
            type: "get",
            context: this,
            dataType: "json",
            success: function (data) {
                $(".quantity[data-uuid=\"" + uuid + "\"]").val(quantity);
                $(".coupons-and-promos").empty().append(data.totals.discountsHtml);
                updateCartTotals(data);
                updateApproachingDiscounts(data.approachingDiscounts);
                updateAvailability(data, uuid);
                validateBasket(data);
                if (quantity > Number(preSelectQty)) {
                    gtm.addToCart($productContainer, "cart", quantity - Number(preSelectQty), isMinicart);
                } else if (quantity < Number(preSelectQty)) {
                    gtm.removeFromCart($productContainer, Number(preSelectQty) - quantity, isMinicart);
                }
                $(this).attr("data-pre-select-qty", quantity);

                $("body").trigger("cart:update", data);
                
                window.location.reload();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $(this).val(parseInt(preSelectQty, 10));
                    $.spinner().stop();
                }
            }
        });
    });

    $(".shippingMethods").on("change", function () {
        var url = $(this).attr("data-actionUrl");
        var urlParams = {
            methodID: $(this).find(":selected").attr("data-shipping-id")
        };

        $(".totals").spinner().start();
        $("body").trigger("cart:beforeShippingMethodSelected");
        $.ajax({
            url: url,
            type: "post",
            dataType: "json",
            data: urlParams,
            success: function (data) {
                if (data.error) {
                    window.location.href = data.redirectUrl;
                } else {
                    $(".coupons-and-promos").empty().append(data.totals.discountsHtml);
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    validateBasket(data);
                }

                $("body").trigger("cart:shippingMethodSelected", data);
                window.location.reload();
            },
            error: function (err) {
                if (err.redirectUrl) {
                    window.location.href = err.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $(".falabella-code-form").on("submit", function (e) {
        e.preventDefault();
        $.spinner().start();
        var $form =  $(this);
        var formData = $form.serialize();

        $(".falabella-error-message").hide();
        $(".falabella-code-form .form-control").removeClass("is-invalid");

        $.ajax({
            url: $form.attr("action"),
            type: "GET",
            dataType: "json",
            data: formData,
            success: function (data) {
                $("#falabellaCode").val(data.falabellaCode);

                if (data.error) {
                    $(".falabella-code-form .form-control").attr("aria-describedby", "invalidFalabellaCode");
                    $(".falabella-error-message").show();
                } else {
                    updateCartTotals(data.basket);
                    validateBasket(data.basket);

                    $(".falabella-code-btn-add").addClass("d-none");
                    $(".falabella-code-btn-remove").removeClass("d-none");
                }

                window.location.reload();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    $(".falabella-code-form .form-control").attr("aria-describedby", "invalidFalabellaCode");
                    $(".falabella-error-message").show();
                    $.spinner().stop();
                }
            }
        });
        return false;
    });

    $("body").on("click", ".falabella-code-btn-remove", function (e) {
        e.preventDefault();
        $.spinner().start();
        var url = $(this).data("action");

        $.ajax({
            url: url,
            type: "get",
            dataType: "json",
            success: function (data) {
                updateCartTotals(data);
                validateBasket(data);
                $("#falabellaCode").val("");
                $(".falabella-code-btn-add").removeClass("d-none");
                $(".falabella-code-btn-remove").addClass("d-none");
                window.location.reload();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    utilsServices.createErrorNotification(err.responseJSON.errorMessage, $notificationAlert, true, 3000);
                    $.spinner().stop();
                }
            }
        });
    });

    $(".promo-code-form").on("submit", function (e) {
        e.preventDefault();
        $.spinner().start();
        var $form =  $(this);
        var formData = $form.serialize();
        $(".coupon-error-message").hide();
        $(".promo-code-form .form-control").removeClass("is-invalid");

        $.ajax({
            url: $form.attr("action"),
            type: "GET",
            dataType: "json",
            data: formData,
            success: function (data) {
                validateBasket(data.basket);
                updateCartTotals(data.basket);
                updateApproachingDiscounts(data.basket.approachingDiscounts);
                $(".coupons-and-promos").empty().append(data.basket.totals.discountsHtml);
                $("#couponCode").val(data.couponCode);
                $("body").trigger("cart:update", data.basket);

                if (data.error) {
                    $(".promo-code-form .form-control").attr("aria-describedby", "invalidCouponCode");
                    $(".coupon-error-message").show();
                } else {
                    $(".add-coupon-button").addClass("d-none");
                    $(".remove-coupon-button").removeClass("d-none");
                }

                window.location.reload();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    $(".promo-code-form .form-control").attr("aria-describedby", "invalidCouponCode");
                    $(".coupon-error-message").show();
                    $.spinner().stop();
                }
            }
        });
        return false;
    });

    $("body").on("click", ".remove-coupon-button", function (e) {
        e.preventDefault();
        $.spinner().start();
        var url = $(this).data("action");

        $.ajax({
            url: url,
            type: "get",
            dataType: "json",
            success: function (data) {
                validateBasket(data);
                updateCartTotals(data);
                updateApproachingDiscounts(data.approachingDiscounts);
                $(".coupons-and-promos").empty().append(data.totals.discountsHtml);

                if (data.error) {
                    utilsServices.createErrorNotification(data.message, $notificationAlert, true, 3000);
                } else {
                    $("#couponCode").val("");
                    $(".add-coupon-button").removeClass("d-none");
                    $(".remove-coupon-button").addClass("d-none");
                }

                $("body").trigger("cart:update", data);
                window.location.reload();
                
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    utilsServices.createErrorNotification(err.responseJSON.errorMessage, $notificationAlert, true, 3000);

                    $.spinner().stop();
                }
            }
        });
    });

    $("body").on("click", ".cart-page .bonus-product-button", function () {
        $.spinner().start();
        $(this).addClass("launched-modal");
        $.ajax({
            url: $(this).data("url"),
            method: "GET",
            dataType: "json",
            success: function (data) {
                base.methods.editBonusProducts(data);
                window.location.reload();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });

    $("body").on("hidden.bs.modal", "#chooseBonusProductModal", function () {
        $("#chooseBonusProductModal").remove();
        $(".modal-backdrop").remove();
        $("body").removeClass("modal-open");

        if ($(".cart-page").length) {
            $(".launched-modal .btn-outline-primary").trigger("focus");
            $(".launched-modal").removeClass("launched-modal");
        } else {
            $(".product-detail .add-to-cart").focus();
        }
    });

    $("body").on("click", ".cart-page .product-edit .edit, .cart-page .bundle-edit .edit", function (e) {
        e.preventDefault();

        var editProductUrl = $(this).attr("href");
        getModalHtmlElement();
        fillModalElement(editProductUrl);
    });

    $("body").on("shown.bs.modal", "#editProductModal", function () {
        $("#editProductModal").siblings().attr("aria-hidden", "true");
        $("#editProductModal .close").focus();
    });

    $("body").on("hidden.bs.modal", "#editProductModal", function () {
        $("#editProductModal").siblings().attr("aria-hidden", "false");
    });

    $("body").on("keydown", "#editProductModal", function (e) {
        var focusParams = {
            event: e,
            containerSelector: "#editProductModal",
            firstElementSelector: ".close",
            lastElementSelector: ".update-cart-product-global",
            nextToLastElementSelector: ".modal-footer .quantity-select"
        };
        focusHelper.setTabNextFocus(focusParams);
    });

    $("body").on("product:updateAddToCart", function (e, response) {
        // update global add to cart (single products, bundles)
        var dialog = $(response.$productContainer)
            .closest(".quick-view-dialog");

        $(".update-cart-product-global", dialog).attr("disabled",
            !$(".global-availability", dialog).data("ready-to-order")
            || !$(".global-availability", dialog).data("available")
        );
    });

    $("body").on("product:updateAvailability", function (e, response) {
        // bundle individual products
        $(".product-availability", response.$productContainer)
            .data("ready-to-order", response.product.readyToOrder)
            .data("available", response.product.available)
            .find(".availability-msg")
            .empty()
            .html(response.message);


        var dialog = $(response.$productContainer)
            .closest(".quick-view-dialog");

        if ($(".product-availability", dialog).length) {
            // bundle all products
            var allAvailable = $(".product-availability", dialog).toArray()
                .every(function (item) { return $(item).data("available"); });

            var allReady = $(".product-availability", dialog).toArray()
                .every(function (item) { return $(item).data("ready-to-order"); });

            $(".global-availability", dialog)
                .data("ready-to-order", allReady)
                .data("available", allAvailable);

            $(".global-availability .availability-msg", dialog).empty()
                .html(allReady ? response.message : response.resources.info_selectforstock);
        } else {
            // single product
            $(".global-availability", dialog)
                .data("ready-to-order", response.product.readyToOrder)
                .data("available", response.product.available)
                .find(".availability-msg")
                .empty()
                .html(response.message);
        }
    });

    $("body").on("product:afterAttributeSelect", function (e, response) {
        if ($(".modal.show .product-quickview .bundle-items").length) {
            $(".modal.show").find(response.container).data("pid", response.data.product.id);
            $(".modal.show").find(response.container).find(".product-id").text(response.data.product.id);
        } else {
            $(".modal.show .product-quickview").data("pid", response.data.product.id);
        }
    });

    $("body").on("change", ".quantity-select", function () {
        var selectedQuantity = $(this).val();
        $(".modal.show .update-cart-url").data("selected-quantity", selectedQuantity);
    });

    $("body").on("change", ".options-select", function () {
        var selectedOptionValueId = $(this).children("option:selected").data("value-id");
        $(".modal.show .update-cart-url").data("selected-option", selectedOptionValueId);
    });

    $("body").on("click", ".update-cart-product-global", function (e) {
        e.preventDefault();

        var updateProductUrl = $(this).closest(".cart-and-ipay").find(".update-cart-url").val();
        var selectedQuantity = $(this).closest(".cart-and-ipay").find(".update-cart-url").data("selected-quantity");
        var selectedOptionValueId = $(this).closest(".cart-and-ipay").find(".update-cart-url").data("selected-option");
        var uuid = $(this).closest(".cart-and-ipay").find(".update-cart-url").data("uuid");

        var form = {
            uuid: uuid,
            pid: base.getPidValue($(this)),
            quantity: selectedQuantity,
            selectedOptionValueId: selectedOptionValueId
        };

        $(this).parents(".card").spinner().start();

        $("body").trigger("cart:beforeUpdate");

        if (updateProductUrl) {
            $.ajax({
                url: updateProductUrl,
                type: "post",
                context: this,
                data: form,
                dataType: "json",
                success: function (data) {
                    $("#editProductModal").modal("hide");

                    $(".coupons-and-promos").empty().append(data.cartModel.totals.discountsHtml);
                    updateCartTotals(data.cartModel);
                    updateApproachingDiscounts(data.cartModel.approachingDiscounts);
                    updateAvailability(data.cartModel, uuid);
                    updateProductDetails(data, uuid);

                    if (data.uuidToBeDeleted) {
                        $(".uuid-" + data.uuidToBeDeleted).remove();
                    }

                    validateBasket(data.cartModel);

                    $("body").trigger("cart:update", data);
                    window.location.reload();
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification(err.responseJSON.errorMessage);
                        $.spinner().stop();
                    }
                }
            });
        }
    });

    $(".checkout-btn").on("click", function (e) {
        if ($("#conveniosSelect").val() === null) {
            //Show error message
            e.preventDefault();
        }

        if ($(".address-container.noaddress").length > 0) {
            $(".error-no-address").removeClass("d-none");
        } else {
            $(".error-no-address").addClass("d-none");
        }
        
    });

    $("#conveniosSelect").on("change", function () {
        var selectedValue = $(this).val();
        var url = $(this).data("url");

        $.spinner().start();

        $.ajax({
            url: url,
            type: "post",
            dataType: "json",
            data: { convenio: selectedValue },
            success: function () {
                window.location.reload();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    utilsServices.createErrorNotification(err.responseJSON.errorMessage, $notificationAlert, true, 3000);
                    $.spinner().stop();
                }
            }
        });
    });

    $(".edit-address-modal").on("click", function () {
        const $modalHeader = $(".modal-header h5");
        const resource = $(this).data("resource");

        $modalHeader.text(resource);
    });

    /**
    * Event handler for opening the address modal
    * @param {Object} $this - the clicked element
    * @param {boolean} isEditBtn - whether the clicked element is an edit button
    * @param {Object} $form - the address form inside the modal
    * @param {Object} $address - the selected address option
    * @param {Object} addressData - the data associated with the selected address
    * @param {boolean} isGuest - whether the customer is a guest
    * @param {Object} $addressBookModal - the address book modal
    * @param {Object} $addAddressModal - the add address modal
    */
    $(".js-open-edit-address-modal, .edit-address-modal-btn").on("click", function () {
        const $this = $(this);
        const isEditBtn = $this.data("edit-btn") || false;
        const isEditBtnModal = $this.data("modal-edit-btn") || false;
        const isAddNewAddress = $this.data("add-new-address") || false;
        const $form = $(".address-form", ".modal");
        const $address = isEditBtn ? $(".js-cart-address") : $(".address option:selected", ".modal");
        const addressData = $address.data();
        const isGuest = $(".is-guest-customer").val() === "true";
        const isReadOnly = $this.data("readonly") !== undefined ? $this.data("readonly") : true;
        const $modalHeader = $(".modal-header h5");
        const resource = $this.data("resource");
        const $phone = $("#phone");
        const $rut = $(".form-rut-field");
        const $readOnlyFields = $("#rut, #addressId");
        const readOnlyClass = "readonly";

        // Remove all previous errors
        clearForm($form);

        // Remove masks to re-apply when the modal is open
        $phone.unmask();
        $rut.unmask();

        setTimeout(()=>{
            $modalHeader.text(resource);
        }, 200);

        if ([isEditBtn, isGuest, isEditBtnModal].includes(true)) {
            $form.attr("data-type", "edit");
            methods.populateForm($form, addressData ? addressData : $this.data());

            // Re-apply masks
            utilMask.maskPhone($phone);
            utilMask.maskRUT($rut);

            if (!isGuest && isReadOnly) {
                $readOnlyFields.addClass(readOnlyClass).attr("readonly", true);
            }

        } else if (isAddNewAddress) {
            const { rut } = addressData;
            $form.attr("data-type", "new");
            $form.find("input:not([type='hidden'])").val("");
            $readOnlyFields.removeClass(readOnlyClass).removeAttr("readonly");
            // Remove google suggestions container
            $(".pac-container").hide();

            if (!isGuest) {
                $rut.addClass(readOnlyClass).val(rut).attr("readonly", true);
            }
        } else {
            $form.attr("data-type", "");
        }

        updateCountryInputValue();
        // Re-apply masks
        utilMask.maskPhone($phone);
        utilMask.maskRUT($rut);

        const $addressBookModal = $("#addressBookModal");
        const $addAddressModal = $("#addAddressModal");

        $addressBookModal.modal("hide");
        $addAddressModal.modal("show");

    });

    $("#addAddressModal").on("shown.bs.modal", function () {
        $("body").addClass("modal-open");
        shippingHelpers.methods.initAddressModalHandlers();
    });

    $("#addAddressModal").on("hidden.bs.modal", function () {
        // Remove google suggestions container
        $(".pac-container").hide();
    });

    /**
    * Event handler for the address confirmation modal
    * @param {Object} $form - the form element
    * @param {Object} $address - the selected address element
    * @param {Object} addressData - the data associated with the selected address
    * @param {Object} $addressBookModal - the address book modal element
    * @param {string} url - the URL for the AJAX request
    * @returns {Object} data - the response data from the AJAX request
    */
    $(".js-address-confirm-modal").on("click", function () {
        const $form = $("form.address-form");
        const $address = $(".address option:selected");
        const addressData = $address.length ? $address.data() : "";
        const $addressBookModal = $("#addressBookModal");
        const url = $(this).data("url") + "?isConfirmation=true";

        methods.populateForm($form, addressData);
        updateCountryInputValue();

        $.ajax({
            url: url,
            type: "post",
            dataType: "json",
            data: serializeAddressModalData($form),
            success(data) {
                if (!data.success) {
                    formValidation($form, data);

                    return;
                }

                const shippingAddress = data.order.shipping[0].shippingAddress;
                const customerInfo = data.order.customer;

                shippingHelpers.methods.populateCartAddressCard(shippingAddress, customerInfo);
                $addressBookModal.modal("hide");
            },
            error(err) {
                utilsServices.createErrorNotification(err.message, $notificationAlert, true, 300);
            }
        });
    });

    $(".remove-out-of-stock-btn").on("click", function () {
        var url = $(".remove-all-products").data("url");

        $.spinner().start();

        deleteItemsFromBasket(url);
    });

    base.selectAttribute();
    base.selectSizeAttribute();
    base.colorAttribute();
    base.removeBonusProduct();
    base.selectBonusProduct();
    base.enableBonusProductSelection();
    base.showMoreBonusProducts();
    base.addBonusProductsToCart();
    base.focusChooseBonusProductModal();
    base.trapChooseBonusProductModalFocus();
    base.onClosingChooseBonusProductModal();
    initPopover();

    initCarousel(".recommendations-1 .ahumada-recommendation-carousel", {}, ".recommendations-1 .ahumada-recommendation-carousel-wrapper");
    initCarousel(".recommendations-2 .ahumada-recommendation-carousel", {}, ".recommendations-2 .ahumada-recommendation-carousel-wrapper");
};
